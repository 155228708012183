<template>
  <div class="form">
    <!-- api endpoint form component -->
    <div id="api-endpoint-form" class="form">
      <h2>Editing an Existing API Endpoint</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div v-if="loading">Loading...</div>
      <div v-else class="form-content">
        <div class="form-input">
          <label for="api-endpoint"> Api Endpoint: </label>
          <input
            id="api-endpoint"
            v-model="apiEndpoint.domain"
            name="api_endpoint"
            type="text"
          />
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
    </div>
    <!-- api endpoint form component -->
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import { ApiEndpoint } from "../../types/index";

class EditApiEndpoint extends Vue {
  apiEndpoint: ApiEndpoint = {
    domain: "",
  };
  loading: boolean = true;
  error: string = "";

  created() {
    this.getApiEndpoint(Number(this.$route.params.id)).then(() => {
      this.loading = false;
    });
  }

  async getApiEndpoint(id: number) {
    try {
      const response: AxiosResponse<{ data: ApiEndpoint }> =
        await apiFetch.apiEndpoint.getApiEndpointDetail(id);
      this.apiEndpoint = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async save() {
    try {
      await this.editApiEndpoint(Number(this.$route.params.id));
      this.$router.push({ path: "/api-endpoint" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async editApiEndpoint(id: number) {
    const newApiEndpoint: ApiEndpoint = { ...this.apiEndpoint };
    await apiFetch.apiEndpoint.updateApiEndpointDetail(id, newApiEndpoint);
  }
}
export default EditApiEndpoint;
</script>
